import React, { useState, useEffect } from "react";
import { Table, Tag, Modal, DatePicker, Input, Button, Space } from "antd";
import { getAccount, clearError } from "../../../reducer/adminReducer";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { SearchOutlined } from "@ant-design/icons";

function UserReport() {
  const dispatch = useDispatch();
  const { transList } = useSelector((state) => state.admin);
  const [viewList, setViewList] = useState([]);
  const [viewModal, setViewModal] = useState(false);
  const [filter, setFilter] = useState({ startDate: "", endDate: "" });
  const [filteredUserList, setFilteredUserList] = useState([]);

  useEffect(() => {
    dispatch(getAccount());
    dispatch(clearError());
    // eslint-disable-next-line
  }, []);

  const openView = (rowId) => {
    let rowData = transList.filter((i) => i.id === rowId)[0];
    if (rowData) {
      setViewList(rowData.transactions);
      setViewModal(true);
    }
  };

  const goFilter = () => {
    dispatch(getAccount(filter));
  };

  const columns = [
		{
			title: "User ID",
			dataIndex: "id",
			key: "id",
			align: "center",
		},
		{
			title: "Phone Number",
			dataIndex: "number",
			key: "number",
			align: "center",
		},
		{
			title: "Name",
			dataIndex: "name",
			key: "name",
			align: "center",
		},
		{
			title: "Email Address",
			dataIndex: "email",
			key: "email",
			align: "center",
		},
		{
			title: "Month of Birth ",
			dataIndex: "dob",
			key: "dob",
			render: (date) => {
				return moment(date).format("DD-MM-YYYY");
			},
		},
		{
			title: "State",
			dataIndex: "state",
			key: "state",
		},
		{
			title: "Registered Date",
			dataIndex: "registeredDate",
			key: "registeredDate",
			render: (date) => {
				return moment(date).format("DD-MM-YYYY");
			},
		},
		{
			title: "Tier Name",
			dataIndex: "tierName",
			key: "tierName",
		},
		{
			title: "Total Submissions",
			dataIndex: "totalSubmissions",
			key: "totalSubmissions",
		},
		{
			title: "Total Purchased Amount",
			dataIndex: "totalPurchasedAmount",
			key: "totalPurchasedAmount",
		},
		{
			title: "Total Purchase Unit",
			dataIndex: "totalPurchaseUnit",
			key: "totalPurchaseUnit",
		},
		{
			title: "Prefered Language",
			dataIndex: "preferedLanguage",
			key: "preferedLanguage",
		},
		{
			title: "Prefered Brand",
			dataIndex: "preferedBrand",
			key: "preferedBrand",
		},
		{
			title: "Lifetime Points",
			dataIndex: "lifeTimePoints",
			key: "lifeTimePoints",
		},
		{
			title: "Loyalty Points",
			dataIndex: "loyaltyPoint",
			key: "loyaltyPoint",
		},
		{
			title: "Redeemed Points",
			dataIndex: "redeemedPoints",
			key: "redeemedPoints",
		},

		{
			title: "Point Balance",
			dataIndex: "pointBalance",
			key: "pointBalance",
		},
		// {
		//   title: 'Referral Code',
		//   dataIndex: 'referralCode',
		//   key: 'referralCode',
		// },
		// {
		//   title: 'Refer By',
		//   dataIndex: 'referBy',
		//   key: 'referBy',
		// },
		{
			title: "Verification Status",
			dataIndex: "verified",
			key: "verified",
			align: "center",
			render: (v) => {
				return v ? "Yes" : "No";
			},
		},
		// {
		//   title: "Action",
		//   dataIndex: "id",
		//   key: "id",
		//   render: (id, i) => {
		//     return (
		//       <div key={i}>
		//         <Tag onClick={() => openView(id)} color="blue">
		//           View
		//         </Tag>
		//       </div>
		//     );
		//   },
		// }
	];

  const columnsView = [
    {
      title: "Given Name",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "UniqueCode",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Code Group",
      dataIndex: "group",
      key: "group",
    },
    {
      title: "Point Value",
      dataIndex: "points",
      key: "points",
    },
    {
      title: "Code Redemption Date",
      dataIndex: "scannedDate",
      key: "scannedDate",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "User Registration Date",
      dataIndex: "createdAt",
      key: "createdAt",
      render: (date) => {
        return moment(date).format("DD-MM-YYYY");
      },
    },
    {
      title: "Cell Phone",
      dataIndex: "number",
      key: "number",
    },
  ];

  const generateCSV = (columns, data, filename) => {
    const header = columns.map((column) => column.title).join(",");

    const csvData = [header]
      .concat(
        data.map((item) => {
          return columns
            .map((column) => {
              // Convert the value to a string and enclose in double quotes if it contains commas
              const value = String(item[column.dataIndex]);
              if (value.includes(",")) {
                return `"${value}"`;
              } else {
                return value;
              }
            })
            .join(",");
        })
      )
      .join("\n");

    const blob = new Blob([csvData], { type: "text/csv" });
    const downloadLink = document.createElement("a");
    downloadLink.href = URL.createObjectURL(blob);
    downloadLink.download = filename;
    downloadLink.click();
  };

  const handleDownload = () => {
    generateCSV(columns, transList, "userReport.csv");
  };

  return (
    <div>
      <br />
      <div style={{ color: "black", fontSize: "35px", textAlign: "center" }}>
        CRM User
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          marginTop: "5px",
          marginBottom: "20px",
        }}
      >
        <div>
          <Space.Compact>
            <Input
              prefix={<SearchOutlined />}
              placeholder=" Search here..."
              onChange={(e) => {
                const searchTerm = e.target.value.toLowerCase();
                const filterData = transList.filter((record) => {
                  return Object.values(record).some((value) =>
                    String(value).toLowerCase().includes(searchTerm)
                  );
                });
                setFilteredUserList(filterData);
              }}
              style={{
                height: "40px",
                display: "flex",
                alignItems: "center",
                width: "80%",
              }}
            />
          </Space.Compact>
        </div>
        <div style={{ display: "flex", alignItems: "center", gap: "5px" }}>
          <DatePicker
            label="Start Date"
            value={filter.startDate}
            onChange={(newValue) => {
              setFilter({ ...filter, startDate: newValue });
            }}
            renderInput={(params) => <Input {...params} />}
          />{" "}
          <DatePicker
            style={{ fontSize: "10px" }}
            label="End Date"
            value={filter.endDate}
            onChange={(newValue) => {
              setFilter({ ...filter, endDate: newValue });
            }}
            renderInput={(params) => <Input {...params} />}
          />
          <Button
            style={{
              width: "150px",
              background: "lightblue",
              marginLeft: "10px",
              height: "40px",
            }}
            onClick={() => goFilter()}
          >
            Search
          </Button>
        </div>
        <div>
          <p
            style={{ textDecoration: "underline", color: "gray" }}
            onClick={handleDownload}
          >
            Download Report
          </p>
        </div>
      </div>
      <div className="table-container">
        <Table
          className="management-table"
          columns={columns}
          dataSource={filteredUserList.length ? filteredUserList : transList}
          style={{ objectFit: "contain" }}
        />
      </div>
      <Modal
        title={
          <div style={{ fontSize: "25px" }}>View Transaction's Details</div>
        }
        open={viewModal}
        closable={true}
        footer={null}
        onCancel={() => {
          setViewModal(false);
        }}
      >
        <Table
          className="management-table"
          columns={columnsView}
          dataSource={viewList}
        />
      </Modal>
    </div>
  );
}

export default UserReport;
