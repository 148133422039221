import '../../css/Register.css';
import { Button, Checkbox, Form, Input, Modal } from 'antd';
import { useLocation, useNavigate } from 'react-router-dom';
import myTnc from '../../images/Haleon/pdf/TnC.pdf';
import sgTnC from '../../images/Haleon/pdf/Haleon Rewards 2024 (SG)_TC.pdf';

import axiosClient from '../../utils/axiosClient';
import rejected from '../../images/Haleon/rejected.png';
import { useLocalStorage } from '@uidotdev/usehooks';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import successImg from '../../images/Haleon/checkmark1.png';

const RegisterForm = ({ formConfig }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [form] = Form.useForm();
	const [tempOtp, setTempOtp] = useLocalStorage('tempOtp');
	const [loading, setLoading] = useState(false);
	const [errorModal, setErrorModal] = useState(false);
	const [error, setError] = useState(false);
	const [register, setRegister] = useState(false);
	const [number, setNumber] = useState('');
	const { t } = useTranslation();
	const referralCode = localStorage.getItem('HALEON-REWARDS_REFER_CODE');
	const channel = localStorage.getItem('HALEON-REWARDS_CHANNEL');
	const promoterId = localStorage.getItem('HALEON-REWARDS_PROMOTER_ID');
	const promoterCode = localStorage.getItem('HALEON-REWARDS_PROMOTER_CODE');
	const storeId = localStorage.getItem('HALEON-REWARDS_STORE_ID');
	const storeCode = localStorage.getItem('HALEON-REWARDS_STORE_CODE');
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;
	const regexPhoneNum = isSGRegion ? /^([0-9]{8})$/ : /^(1[0-9]{8,9})$/;
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');

	useEffect(() => {
		const registerCode = referralCode || storeCode || promoterCode;
		if (registerCode && form) {
			form.setFieldValue('registerCode', registerCode);
		}
	}, [referralCode, promoterCode, storeCode, form]);

	useEffect(() => {
		if (location.state?.phone) {
			form.setFieldsValue({ phone: location.state?.phone });
			setNumber(location.state?.phone);
		}
	}, [number]);

	const handleSubmit = async (values) => {
		setLoading(true);
		const _values = values;
		_values.registeredByPromoterId = promoterId;
		_values.registeredByRetailerId = storeId;
		_values.referralCode = referralCode;
		_values.channel = channel;
		// validate register code: either it's promoter code or store code
		const registerCode = _values.registerCode;
		if (registerCode && !referralCode) {
			// store code have '-' in it and promoter code doesn't
			const isRetailerCode = registerCode.split('-').length > 1;
			const endpoint = isRetailerCode
				? `/public/retailers?storeCode=${registerCode}`
				: `/public/promoters?promoterCode=${registerCode}`;
			try {
				const res = await axiosClient.get(`${endpoint}`);
				if (
					res.data.length > 0 &&
					isRetailerCode &&
					res.data[0]?.storeCode === registerCode
				) {
					_values.registeredByRetailerId = res.data[0].id;
					_values.storeId = res.data[0].id;
				} else if (
					res.data.length > 0 &&
					!isRetailerCode &&
					res.data[0]?.promoterCode === registerCode
				) {
					_values.promoterId = res.data[0].id;
				} else {
					setLoading(false);
					setError('Invalid Code');
					setErrorModal(true);
					return;
				}
			} catch (err) {
				setLoading(false);
				console.error(err);
				setError(err.response.data.message);
				setErrorModal(true);
				return;
			}
		}
		try {
			const register = {
				name: _values.name,
				phone: (isSGRegion ? '65' : '60') + location.state?.phone,
				email: _values.email,
				companyId: isSGRegion
					? process.env.REACT_APP_COMPANY_ID_SG
					: process.env.REACT_APP_COMPANY_ID_MY,
				referralCode: _values.referralCode,
				channel: _values.channel,
				registeredByPromoterId: isSGRegion ? null :_values.promoterId,
				registeredByRetailerId: _values.registeredByRetailerId,
				storeId: _values.storeId,
				otp: tempOtp,
			};

			const res = await axiosClient.post('/auth/register-otp', register);
			if (res) {
				setTempOtp(null);
				localStorage.setItem('HALEON-REWARDS_USER_TOKEN', res.data.token);
				setRegister(true);
				// navigate("/home"+window.location.search);

				// remove referral code,promoterId, channel from local storage
				localStorage.removeItem('HALEON-REWARDS_REFER_CODE');
				localStorage.removeItem('HALEON-REWARDS_PROMOTER_CODE');
				localStorage.removeItem('HALEON-REWARDS_PROMOTER_ID');
				localStorage.removeItem('HALEON-REWARDS_CHANNEL');
				localStorage.removeItem('HALEON-REWARDS_STORE_CODE');
				localStorage.removeItem('HALEON-REWARDS_STORE_ID');
			}
		} catch (err) {
			setLoading(false);
			console.error(err);
			setError(err.response.data.message);
			setErrorModal(true);
		}
	};

	if (!formConfig) return null;

	// get the form input value
	// const [form] = Form.useForm();

	const regexName = /^[a-zA-Z ]+( [a-zA-Z ]+)*$/;

	return (
		<>
			<Form
				form={form}
				onFinish={handleSubmit}
				onFinishFailed={(e) => console.error('submit fail', e.errorFields)}
				// disabled={loading}
				aria-label="form"
				autoComplete="off"
				scrollToFirstError
			>
				<div style={{ padding: '0 5%', width: 'auto' }}>
					<Form.Item
						name="name"
						hasFeedback
						rules={[
							{
								required: true,
								message: 'this is required',
							},
							({ getFieldValue }) => ({
								validator(_, value) {
									if (value && value?.length > 0) {
										if (regexName.test(value)) {
											return Promise.resolve(true);
										}
										return Promise.reject(new Error('Invalid name format!'));
									}
									return Promise.resolve(true);
								},
							}),
						]}
					>
						<Input
							type="text"
							name="name"
							prefix="*"
							placeholder={t('Full Name')}
							className="form-input"
							styles={{
								prefix: { color: 'brown' },
							}}
							required={true}
						/>
					</Form.Item>
					<div
						style={{
							display: 'flex',
							justifyContent: 'left',
							background: 'transparent',
						}}
					>
						<div
							style={{
								borderRadius: '8px',
								border: '2px solid #DEDEDE',
								background: 'transparent',
								width: '13%',
								height: '45px',
								fontSize: '12px',
								color: 'white',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
							}}
						>
							<span>+{isSGRegion ? '65' : '60'}</span>
						</div>
						<Form.Item
							name="phone"
							validateDebounce={500}
							hasFeedback
							className="login-text-color"
							style={{ fontSize: '12px', width: '110%', textAlign: 'left' }}
							rules={[
								{
									required: true,
									message: 'Phone is required',
								},
								({ getFieldValue }) => ({
									validator(_, value) {
										if (value && value?.length > 0) {
											if (regexPhoneNum.test(value)) {
												return Promise.resolve(true);
											}
											return Promise.reject(
												new Error('Invalid phone number format!')
											);
										}
										return Promise.resolve(true);
									},
								}),
							]}
						>
							<Input
								type="tel"
								name="phone"
								style={{
									borderRadius: '8px',
									border: '2px solid #DEDEDE',
									background: 'transparent',
									width: '95%',
									display: 'flex',
									justifyContent: 'right',
									alignItems: 'center',
									margin: 'auto',
									height: '45px',
									position: 'relative',
									left: '2%',
									color: 'white',
								}}
								className="input-placeholder"
								placeholder="Your phone number"
							/>
						</Form.Item>
					</div>
					<Form.Item
						name="email"
						validateDebounce={500}
						hasFeedback
						rules={[{ type: 'email', message: 'Invalid email format!' }]}
					>
						<Input
							type="email"
							name="email"
							className="form-input"
							placeholder={t('Email')}
							// prefix="*"
							// styles={{
							// 	prefix: { color: 'brown' },
							// }}
							// required={true}
						/>
					</Form.Item>
					{!isSGRegion && <Form.Item name="registerCode">
						<Input
							value={referralCode || storeCode || promoterCode || ''}
							type="registerCode"
							name="registerCode"
							className="form-input"
							placeholder={
								isSGRegion
									? 'Referral Code'
									: t('Referral Code/Store Code/Promoter Code')
							}
						/>
					</Form.Item>}
				</div>

				{/* <Form.Item name="number" className="form-input-box">
					<Input
						type="number"
						name="number"
						onChange={isNumber}
						className="form-input"
						required={true}
					/>
					{number ? (
						''
					) : (
						<label className="t-white placeholder">Mobile Number</label>
					)}
				</Form.Item> */}

				<div className="text-center">
					<div className="mt-2 ml-2" style={{ maxWidth: '100%' }}>
						<div style={{ display: 'flex' }}>
							<Checkbox
								required
								className="ml-1 t-white tnc transparent-checkbox"
							>
								{getLanguage === 'bm' ? (
									<p
										className="fontSize-14"
										style={{
											lineHeight: '18px',
											fontWeight: '400',
											maxWidth: '100%',
											textAlign: 'left',
											margin: '0',
										}}
									>
										Saya bersetuju dengan{' '}
										<a
											className="t-white text-deco-underline"
											href={isSGRegion ? sgTnC : myTnc}
											target="_blank"
											rel="noopener noreferrer"
										>
											Terma dan Syarat
										</a>{' '}
										Haleon serta{' '}
										<a
											className="t-white text-deco-underline"
											target="_blank"
											href={
												isSGRegion
													? 'https://www.privacy.haleon.com/en-sg/general/general-full-text/'
													: 'https://www.privacy.haleon.com/en-my/general/general-full-text/'
											}
											rel="noreferrer"
										>
											Dasar Privasi
										</a>
									</p>
								) : getLanguage === 'cn' ? (
									<p
										className="fontSize-14"
										style={{
											lineHeight: '18px',
											fontWeight: '400',
											maxWidth: '100%',
											textAlign: 'left',
											margin: '0',
										}}
									>
										我同意Haleon的活动
										<a
											className="t-white text-deco-underline"
											href={isSGRegion ? sgTnC : myTnc}
											target="_blank"
											rel="noopener noreferrer"
										>
											规则与条款
										</a>
										及
										<a
											className="t-white text-deco-underline"
											target="_blank"
											href={
												isSGRegion
													? 'https://www.privacy.haleon.com/en-sg/general/general-full-text/'
													: 'https://www.privacy.haleon.com/en-my/general/general-full-text/'
											}
											rel="noreferrer"
										>
											隐私政策
										</a>
									</p>
								) : (
									<>
										<p
											className="fontSize-14"
											style={{
												lineHeight: '18px',
												fontWeight: '400',
												maxWidth: '100%',
												textAlign: 'left',
												margin: '0',
											}}
										>
											I agree to the Haleon’s{' '}
											<a
												className="t-white text-deco-underline"
												href={isSGRegion ? sgTnC : myTnc}
												target="_blank"
												rel="noopener noreferrer"
											>
												Terms and Conditions
											</a>{' '}
										</p>
										<p
											className="fontSize-14"
											style={{
												lineHeight: '18px',
												fontWeight: '400',
												maxWidth: '100%',
												textAlign: 'left',
												margin: '0',
											}}
										>
											and the{' '}
											<a
												className="t-white text-deco-underline"
												target="_blank"
												href={
													isSGRegion
														? 'https://www.privacy.haleon.com/en-sg/general/general-full-text/'
														: 'https://www.privacy.haleon.com/en-my/general/general-full-text/'
												}
												rel="noreferrer"
											>
												Privacy Policy
											</a>
										</p>
									</>
								)}
							</Checkbox>
						</div>
					</div>
					<div className="mt-2 ml-2" style={{ maxWidth: '100%' }}>
						<div style={{ display: 'flex' }}>
							<Checkbox
								required
								className="ml-1 t-white tnc transparent-checkbox"
							>
								{getLanguage === 'bm' ? (
									<p
										className="fontSize-14"
										style={{
											lineHeight: '18px',
											fontWeight: '400',
											maxWidth: '100%',
											textAlign: 'left',
											margin: '0',
										}}
									>
										Saya membenarkan Haleon untuk berkongsi data peribadi saya*
										dengan pihak ketiga yang terpilih untuk menyediakan
										perkhidmatan nilai tambah sebagai ahli Haleon Rewards.
									</p>
								) : getLanguage === 'cn' ? (
									<p
										className="fontSize-14"
										style={{
											lineHeight: '18px',
											fontWeight: '400',
											maxWidth: '100%',
											textAlign: 'left',
											margin: '0',
										}}
									>
										我授权Haleon与特定的第三方共享我的个人数据，以便作为Haleon
										Rewards会员提供增值服务。
									</p>
								) : (
									<>
										<p
											className="fontSize-14"
											style={{
												lineHeight: '18px',
												fontWeight: '400',
												maxWidth: '100%',
												textAlign: 'left',
												margin: '0',
											}}
										>
											I authorize Haleon to share my personal
										</p>
										<p
											className="fontSize-14"
											style={{
												lineHeight: '18px',
												fontWeight: '400',
												maxWidth: '100%',
												textAlign: 'left',
												margin: '0',
											}}
										>
											data* with selected third parties to provide
										</p>
										<p
											className="fontSize-14"
											style={{
												lineHeight: '18px',
												fontWeight: '400',
												maxWidth: '100%',
												textAlign: 'left',
												margin: '0',
											}}
										>
											value-added services as a Haleon Rewards
										</p>
										<p
											className="fontSize-14"
											style={{
												lineHeight: '18px',
												fontWeight: '400',
												maxWidth: '100%',
												textAlign: 'left',
												margin: '0',
											}}
										>
											member.
										</p>
									</>
								)}
							</Checkbox>
						</div>
					</div>
				</div>

				{error ? (
					<div style={{ color: 'red', textAlign: 'center' }}>{error}</div>
				) : null}

				<div style={{ padding: '0 5%', width: 'auto', paddingBottom: '5%' }}>
					<Form.Item className="form-input-box">
						<Button
							htmlType="submit"
							block
							loading={loading}
							className="form-submit-btn mb-3 mt-2"
						>
							{t('Submit')}
						</Button>
					</Form.Item>
					{/* <p className="t-white mt-1 text-center fontSize-14">
						Already have an account?{' '}
						<span
							style={{ color: '#30E700' }}
							onClick={() =>
								navigate('/login', { state: { storeId, referId } })
							}
						>
							Login Here
						</span>
					</p> */}
				</div>
			</Form>

			<div>
				<Modal open={register} footer={null} closable={false} centered>
					<div className="text-center">
						<img src={successImg} alt="" style={{ width: '22%' }} />
						<h2 style={{ fontWeight: '700', fontSize: '20px' }}>
							{t('Success')}!
						</h2>
						<p
							className="text-center mt-1 font-16 font-weight-400"
							// style={{ lineHeight: '15px' }}
						>
							{t('Your phone number has been successfully verified')}.
						</p>
						<Button
							type="primary"
							className="mt-3 text-deco-none"
							style={{
								color: 'black',
								width: '90%',
								background: '#30E700',
								fontWeight: '500',
								borderRadius: '5px',
								display: 'flex',
								justifyContent: 'center',
								alignItems: 'center',
								margin: 'auto',
								fontWeight: '700',
								marginTop: '5%',
								fontSize: '16px',
							}}
							onClick={() => navigate('/home' + window.location.search)}
						>
							{t('DONE')}
						</Button>
					</div>
				</Modal>

				<Modal
					open={errorModal}
					centered
					footer={null}
					// closable={false}
					onCancel={() => setErrorModal(false)}
					width={300}
				>
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<img src={rejected} alt="" style={{ width: '20%' }}></img>
					</div>
					<br />
					<div
						style={{
							fontWeight: 'bold',
							fontSize: '20px',
							textAlign: 'center',
						}}
					>
						{/* Invalid QR Code, please rescan a validate QR again */}
						{error}
					</div>
				</Modal>
			</div>
		</>
	);
};

export default RegisterForm;
