import '../../css/Register.css';
import RegisterForm from '../../components/forms/RegisterForm';
import img1 from '../../images/Haleon/happyFamily.png';
import { useTranslation } from 'react-i18next';
import haleonLogo from '../../images/Haleon/HaleonLogo.svg';
import Whatsapp from '../../components/Whatsapp';
import { useLocation } from 'react-router-dom';

const RegisterPage = ({ t }) => {
	const location = useLocation();
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;

	return (
		<div className="text-center">
			<div className="logo-position">
				<img src={haleonLogo} alt="haleon" style={{ width: '30%' }} />
				{/* <Whatsapp/> */}
			</div>
			<img src={img1} alt="pic" className="w-100" />
			<div className="layout-position">
				<div className="register-card" style={{ textAlign: 'center' }}>
					<br />
					<h2>{t('Registration')}</h2>
					<div className="mt-1"></div>
					<hr
						style={{
							backgroundColor: '#30EA03',
							height: '3px',
							width: '40%',
							marginTop: '5px',
							border: 'none',
							margin: 'auto',
						}}
					/>
					<p className="mt-1 text-center">
						{t('Please complete the form to proceed')}
					</p>
					<div className="mt-3">
						<RegisterForm
							formConfig={{
								label: 'Number',
								placeholder: 'Enter Your Phone Number',
								layout: 'vertical',
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default RegisterPage;
