import '../../css/Home.css';
import { Button, Flex, Modal, Progress, Spin, Carousel } from 'antd';
import // checkRegisterReward,
// checkUserSession,
// checkVouchers,
// fetchHistory,
'../../reducer/userReducer';
import { useDispatch, useSelector } from 'react-redux';
import { useEffect, useState } from 'react';
import HowToEarnPoints from '../../components/Modal/HowToEarnPoints';
import MainHeader from '../../components/MainHeader';
import ScrollableImages from '../../components/ScrollableImages';
import UserFooter from '../../components/UserFooter';
// import approvedPic from '../../images/Haleon/approved.png';
import bronzeMedal from '../../images/Haleon/bronzeMedal.png';
import carousel1 from '../../images/Haleon/carousel.png';
import carousel2 from '../../images/Haleon/carouselCountdown.png';
// import { getPointShop } from '../../reducer/userDetailsReducer';
import goldMedal from '../../images/Haleon/goldMedal1.png';
// import grab from '../../images/Haleon/Grab.svg';
import home from '../../images/Haleon/home-pic.png';
import icon from '../../images/Haleon/user.png';
// import icon1 from '../../images/Haleon/documentupload.png';
// import line from '../../images/Haleon/line.png';
import platinumMedal from '../../images/Haleon/platinum.png';
// import pendingPic from '../../images/Haleon/pending.png';
// import rejectedPic from '../../images/Haleon/rejected.png';
import silverMedal from '../../images/Haleon/silverMedal1.png';
// import starBrands from '../../images/Haleon/Home/StarBrands.png';
import store1 from '../../images/Haleon/StorewLink/Panaflex.png';
import store2 from '../../images/Haleon/StorewLink/Scotts.png';
import store3 from '../../images/Haleon/StorewLink/Sensodyne.png';
import store4 from '../../images/Haleon/StorewLink/Centrum.png';
import store5 from '../../images/Haleon/StorewLink/Caltrate.png';
import store6 from '../../images/Haleon/StorewLink/Polident.png';
import storesg1 from '../../images/Haleon/StorewLink/paradontax.png';
import storeImg from '../../images/Haleon/CheckParticipatingStores.png';
// import tng from '../../images/Haleon/TNG.svg';
// import uploadNew from '../../images/Haleon/add.png';
import { useNavigate } from 'react-router';
import user1 from '../../images/Haleon/browseAllRewards.png';
import user2 from '../../images/Haleon/HowToEarnPoints.png';
import welcome from '../../images/Haleon/Home/WelcomeReward.png';
import axiosClient from '../../utils/axiosClient';
import { format, startOfMonth } from 'date-fns';
import { objectToURLSearchParamsString } from '../../utils/axiosHelper';
import { useSearchParams } from 'react-router-dom';
import Questionnaire from '../../components/Modal/Questionnaire';
import moment from 'moment';
import Sg_carousel from '../../components/Sg_carousel';
// import ComingSoon from '../../components/Modal/ComingSoon';
import badgeImg from '../../images/Haleon/Group 38355.png';
import JubileeQuestionnaire from '../../components/Modal/JubileeQuestionnaire';

const Home = ({ t }) => {
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();

	const [isWPModalOpen, setIsWPModalOpen] = useState(false);
	// check region based on the url
	const isSGRegion =
		window.location.hostname.replace('www.', '') ===
		process.env.REACT_APP_SG_DOMAIN;

	// any valid channel/chain names except CONFERENCE
	const WELCOME_POINT_CAMPAIGN_ID = isSGRegion
		? process.env.REACT_APP_WELCOME_POINT_CAMPAIGN_ID_SG
		: process.env.REACT_APP_WELCOME_POINT_CAMPAIGN_ID_MY;

	// check for new channel, CONFERENCE
	const CONFERENCE_WELCOME_POINT_CAMPAIGN_ID = isSGRegion
		? 'ad9c34cc-5393-4ed5-b60a-9d820019ed06'
		: '233fa361-eb50-4232-a802-75bac8b22413';

	const WELCOME_POINTS_10 = isSGRegion
		? '1df6f105-2e30-4df8-9958-1faaeb3efe24'
		: '3a7c1d58-e62b-40a5-9147-5d1769da1c9e';

	const WELCOME_POINTS_100 = isSGRegion
		? '8d3137ba-3f70-481b-894f-0bdddb5043be'
		: 'bbd2b5a6-26aa-478b-841a-bb9da0b09bea';

	const [wpClaimedMsg, setWPClaimedMsg] = useState('');
	const [wpClaimBtnLabel, setWPClaimBtnLabel] = useState(t('Claim!'));
	const [wpRewardId, setWPRewardId] = useState();
	// isSGRegion
	// 	? '1df6f105-2e30-4df8-9958-1faaeb3efe24'
	// 	: '3a7c1d58-e62b-40a5-9147-5d1769da1c9e'

	// questionnaire campaign id
	const QUESTIONNAIRE_ID = '1a82cf7d-1e40-44a9-8687-815855835b9d';
	const JUBILEE_QUESTIONNAIRE_ID = 'af612ab0-4dfc-4e77-9ada-aca813ab4b21';

	// const [isWPClaimed, setIsWPClaimed] = useState(false);
	// To get the value
	let isWPClaimed = localStorage.getItem('isWPClaimed') === 'true';
	const getLanguage = localStorage.getItem('HALEON-REWARDS_LANG');
	const [isSoon, setIsSoon] = useState(false);
	const [howTo, setHowTo] = useState(false);
	const dispatch = useDispatch();
	const [userData, setUserData] = useState();
	const [receiptData, setReceiptData] = useState();
	const [receiptModal, setReceiptModal] = useState(false);
	const currDate = new Date();
	const currMonth = currDate.getMonth();
	const [displayQues, setDisplayQues] = useState(false);
	const [displayPopUp, setDisplayPopUp] = useState(false);
	const [questionSubmitted, setQuestionSubmitted] = useState(false);
	const allMonths = [
		'January',
		'February',
		'March',
		'April',
		'May',
		'June',
		'July',
		'August',
		'September',
		'October',
		'November',
		'December',
	];
	const getCurrMonth = allMonths[currMonth];
	// const [view, setView] = useState(false);
	// const [points, setPoints] = useState();
	const [uploadHistory, setUploadHistory] = useState([]);
	const userToken = localStorage.getItem(`HALEON-REWARDS_USER_TOKEN`);
	const _referCode = searchParams.get('referCode');
	const _channel = searchParams.get('channel');
	const _storeCode = searchParams.get('storeCode');
	const _promoterCode = searchParams.get('promoterCode');
	const [rewardDetected, setRewardDetected] = useState(
		localStorage.getItem('REWARD_DETECTED') === 'true'
	);
	const [eligibleRewards, setEligibleRewards] = useState([]);
	const [isLoading, setIsLoading] = useState(false);
	const [jubileeQuestionSubmitted, setJubileeQuestionSubmitted] =
		useState(true);

	useEffect(() => {
		if (_storeCode && _storeCode !== 'null' && _storeCode !== 'undefined') {
			localStorage.setItem('HALEON-REWARDS_STORE_CODE', _storeCode);
		}
		if (_referCode && _referCode !== 'null' && _referCode !== 'undefined') {
			localStorage.setItem('HALEON-REWARDS_REFER_CODE', _referCode);
		}
		if (_channel && _channel !== 'null' && _channel !== 'undefined') {
			localStorage.setItem('HALEON-REWARDS_CHANNEL', _channel.toUpperCase());
		}
		if (
			_promoterCode &&
			_promoterCode !== 'null' &&
			_promoterCode !== 'undefined'
		) {
			localStorage.setItem('HALEON-REWARDS_PROMOTER_CODE', _promoterCode);
		}
	}, [_storeCode, _referCode, _promoterCode, _channel]);

	useEffect(() => {
		if (!userToken) {
			navigate('/welcome' + window.location.search);
		}
	}, [userToken]);

	const getRegisterReward = async () => {
		// dispatch(checkRegisterReward({ token: localtoken }));
		setWPClaimBtnLabel('Claiming...');
		try {
			await axiosClient.post(
				'/campaigns/entries',
				{
					campaignId: WELCOME_POINT_CAMPAIGN_ID,
					selectedRewardIds: [wpRewardId],
					type: 'GENERAL',
				},
				{
					headers: {
						...axiosClient.defaults.headers,
						'Accept-Version': '2.0.0',
					},
				}
			);

			// console.log('---res:');
			// console.log(res);
			// handle success case
			setWPClaimedMsg('You have been awarded 10 points');
			setWPClaimBtnLabel('Claimed!');
			setIsWPModalOpen(false);
			window.location.reload();
		} catch (error) {
			// console.log('---error:');
			let errorMsg = error?.response?.data?.message ?? 'Failed to claim...';
			if (errorMsg === 'You have reached the maximum number of entries') {
				// window.alert('You have already claimed the welcome point');
				setWPClaimedMsg('You have been awarded 10 points');
				setWPClaimBtnLabel('Claimed!');
			} else {
				// window.alert(errorMsg);
				setWPClaimedMsg('Failed to claim...');
				console.error(errorMsg);
			}
		}
	};

	useEffect(() => {
		if (!userToken) return;

		const check = async () => {
			try {
				const res = await axiosClient.get('/users/details');
				setUserData(res.data.data.personalInfo);
				// console.log(moment(userData?.createdAt.format('YYYY-MM-DD')))
				if (
					!res?.data?.data?.personalInfo?.registerSource
						?.haleonRewardsFirstLoginDate
				) {
					await updateHaleonRewardLoginDateForNewUser(
						res?.data?.data?.personalInfo?.registerSource
					);
				}
				// setUploadHistory(res.data.data.)
			} catch (error) {
				// navigate(`/login?store=${store}`);
			}
		};
		check();
	}, [userToken]);

	let createdAtMoment = moment(userData?.createdAt).format('YYYY-MM-DD');

	useEffect(() => {
		if (!userToken) return;

		const checkQuestionnaire = async () => {
			try {
				const res = await axiosClient.get('/campaign/questionnaire/get', {
					params: { sourceId: QUESTIONNAIRE_ID },
				});

				if (
					res &&
					res.data.fetchQuestion &&
					(createdAtMoment >= '2024-02-29' || createdAtMoment === '2024-02-29')
				) {
					setDisplayQues(false);
					setQuestionSubmitted(true);
				} else {
					// Introduce a delay before showing the questionnaire
					setTimeout(() => {
						setDisplayQues(true);
					}, 500); // Adjust the delay time in milliseconds as needed
					setQuestionSubmitted(false);
				}
			} catch (err) {
				console.error('error', err);
			}
		};
		if (
			!isSGRegion &&
			!questionSubmitted &&
			(createdAtMoment >= '2024-02-29' || createdAtMoment === '2024-02-29')
		) {
			checkQuestionnaire();
		}
	}, [questionSubmitted, userData?.createdAt]);

	// check for channel JUBILEE
	useEffect(() => {
		if (!userToken) return;

		const jubileeQuestionnaire = async () => {
			try {
				const res = await axiosClient.get('/campaign/questionnaire/get', {
					params: { sourceId: JUBILEE_QUESTIONNAIRE_ID },
				});

				if (
					res &&
					res.data.fetchQuestion === null
				) {
					// setJubileeQuestionSubmitted(false);
					setDisplayQues(true);
					setQuestionSubmitted(false);
				} else {
					// Introduce a delay before showing the questionnaire
					setTimeout(() => {
						setDisplayQues(false);
					}, 500); // Adjust the delay time in milliseconds as needed
					setQuestionSubmitted(true);
				}
			} catch (err) {
				console.error('error', err);
			}
		};
		if (
			userData?.registerSource?.channel?.toUpperCase() === 'JUBILEE' &&
			isSGRegion
		) {
			jubileeQuestionnaire();
		}
	}, [questionSubmitted, userData]);

	const updateHaleonRewardLoginDateForNewUser = async (_registerSourec) => {
		try {
			const res = await axiosClient.post('/profile/update', {
				values: {
					registerSource: {
						...(_registerSourec && { ..._registerSourec }),
						haleonRewardsFirstLoginDate: new Date(),
					},
				},
			});

			// console.log('---res:', res);
		} catch (error) {
			console.error(error);
		}
	};

	// check if welcome point campaign is valid for user. If yes, show modal
	useEffect(() => {
		if (!userToken) return;

		if (userData) {
			const checkWelcomePointCampaign = async () => {
				try {
					let welcomePointCampaignId = WELCOME_POINT_CAMPAIGN_ID;
					const res = await axiosClient.get(
						`/campaigns/${welcomePointCampaignId}/validity`
					);
					if (res?.data?.data?.isValid) {
						// console.log('res?.data?.data?.isValid', res?.data?.data?.isValid);
						const campaignData = res?.data?.data ?? {};
						// console.log('--res:', res);

						campaignData?.rewards[0]?.id &&
							setWPRewardId(
								userData?.registerSource?.channel?.toUpperCase() ===
									'CONFERENCE'
									? WELCOME_POINTS_100
									: WELCOME_POINTS_10
							);
						setWPClaimBtnLabel('Claim!');
						setIsWPModalOpen(true);
					}
				} catch (error) {
					// console.error(e);
					// console.log('---error:');
					let errorMsg = error?.response?.data?.message ?? 'Failed to claim...';
					if (errorMsg === 'You have reached the maximum number of entries') {
						// window.alert('You have already claimed the welcome point');
						setWPClaimedMsg('You have been awarded 10 points');
						setWPClaimBtnLabel('Claimed!');
						// setIsWPClaimed(true);
						localStorage.setItem('isWPClaimed', true);
					} else {
						window.alert(errorMsg);
					}
				}
			};

			// console.log('isWPClaimed:', isWPClaimed);
			if (!isWPClaimed) {
				// console.log('checkWelcomePointCampaign');
				checkWelcomePointCampaign();
			}
		}
	}, [userToken, userData]);

	// useEffect(() => {
	// 	const checkPointHistory = async () => {
	// 		try {
	// 			const res = await axiosClient.get('/campaigns/point-history');
	// 			setPoints(res.data.data);
	// 		} catch (e) {}
	// 	};
	// 	checkPointHistory();
	// }, []);

	// const uploadReceiptForVoucherRewardCampaignId =
	// 	'43bbc55b-dbb6-4f2f-91e6-d68315cca235';

	const fetchUserUploadReceiptCampaignEntries = async (_campaignId) => {
		const query = {
			campaignId: _campaignId,
			isActive: true, // isActive:false are previous rejected resubmissions
			createdAt: startOfMonth(new Date()),
			page: 1,
			perPage: 3,
		};

		const stringQuery = objectToURLSearchParamsString(query);

		const res = await axiosClient.get('/campaign-entries?' + stringQuery);
		if (Array.isArray(res.data.data)) {
			return res.data.data;
		} else {
			console.error(
				'fetchUserUploadReceiptCampaignEntries: Invalid response data'
			);
			return [];
		}
	};

	// const fetchUserUploadReceiptCampaignsEntries = async () => {
	// 	try {
	// 		const uploadForRewards = await fetchUserUploadReceiptCampaignEntries(
	// 			uploadReceiptForVoucherRewardCampaignId
	// 		);

	// 		const _uploadHistories = [...uploadForRewards]
	// 			// filter entries with at least one receipt
	// 			.filter((entry) => entry?.receipts?.length > 0)
	// 			// SORT BY STATUS: APPROVED/COMPLETED -> PENDING -> OTHERS
	// 			.sort(uploadReceiptSorting);

	// 		setUploadHistory(_uploadHistories);
	// 	} catch (err) {
	// 		console.error(err);
	// 	}
	// };

	useEffect(() => {
		if (!userToken) return;

		// fetchUserUploadReceiptCampaignsEntries();
	}, [userToken]);

	const checkReceiptStatus = (rdata) => {
		if (rdata === 'new') {
			navigate('/upload');
		} else {
			setReceiptData(rdata);
			setReceiptModal(true);
		}
	};

	// useEffect(() => {
	//   dispatch(getPointShop());
	// }, [dispatch]);

	// sort by status: APPROVED/COMPLETED -> PENDING -> OTHERs, then createdAt
	const uploadReceiptSorting = (a, b) => {
		return new Date(a.createdAt) - new Date(b.createdAt);
	};

	const uploadHistoryItemsToRender = [1, 2, 3];

	useEffect(() => {
		if (!userToken) return;
		// dispatch(checkVouchers());
	}, [dispatch, userToken]);

	useEffect(() => {
		const checkBadgesEligibility = async () => {
			if (!userToken) return;
			// Hide pop up checking eligible
			// setIsLoading(true);
			try {
				let checkValidMission;
				if (!isSGRegion) {
					checkValidMission = await axiosClient.get(
						`/campaigns/validities?type=MISSION`
					);
				} else {
					const missionWrapperCampaignId =
						process.env.REACT_APP_BADGE_WRAPPER_CAMPAIGN_ID_SG;
					if (!missionWrapperCampaignId) {
						throw new Error('Invalid mission wrapper campaign id');
					}
					checkValidMission = await axiosClient.get(
						`/campaign-wrappers/${missionWrapperCampaignId}/validity`
					);
				}
				const eligibleRewards = isSGRegion
					? checkValidMission.data.data.campaignValidations
					: checkValidMission.data.data;
				const findEligibleRewards = eligibleRewards.some(
					(valid) =>
						valid.isValid === true &&
						valid.validationResults &&
						valid.validationResults.length > 0
				);
				const meetsEntryLimit =
					!isSGRegion || checkValidMission.data.data.totalEntry <= 5;

				setIsLoading(false);
				setRewardDetected(findEligibleRewards && meetsEntryLimit);
				setEligibleRewards(eligibleRewards);
			} catch (err) {
				console.error(err);
				setIsLoading(false);
				setRewardDetected(false);
			}
		};

		checkBadgesEligibility();
	}, [userToken, isSGRegion]);

	useEffect(() => {
		localStorage.setItem('REWARD_DETECTED', rewardDetected);
	}, [rewardDetected]);

	// ========================================================
	// stopwatch function here
	// ========================================================
	const calculateTimeLeft = (targetDate) => {
		const now = new Date();
		const difference = targetDate - now;

		let timeLeft = {};

		if (difference > 0) {
			timeLeft = {
				days: Math.floor(difference / (1000 * 60 * 60 * 24)),
				hours: Math.floor((difference / (1000 * 60 * 60)) % 24),
				minutes: Math.floor((difference / 1000 / 60) % 60),
				seconds: Math.floor((difference / 1000) % 60),
			};
		} else {
			timeLeft = {
				days: 0,
				hours: 0,
				minutes: 0,
				seconds: 0,
			};
		}

		return timeLeft;
	};

	// Calculate the target date based on userData.createdAt
	const targetDate = userData?.createdAt
		? new Date(
				new Date(userData.createdAt).getTime() + 30 * 24 * 60 * 60 * 1000
		  )
		: new Date();

	const [timeLeft, setTimeLeft] = useState(calculateTimeLeft(targetDate));

	useEffect(() => {
		const timer = setInterval(() => {
			const newTimeLeft = calculateTimeLeft(targetDate);
			setTimeLeft(newTimeLeft);
		}, 1000);

		return () => clearInterval(timer); // Cleanup timer on component unmount
	}, [targetDate]);

	const formatTime = (time) => {
		return `${time < 10 ? '0' : ''}${time}`;
	};

	// Format date for testing purpose
	const formatDate = (dateString) => {
		const date = new Date(dateString);
		const options = {
			weekday: 'long',
			year: 'numeric',
			month: 'long',
			day: 'numeric',
		};
		return date.toLocaleDateString('en-US', options);
	};

	// ========================================================
	// ========================================================
	// carousell function here
	// ========================================================
	const onChange = (currentSlide) => {
		console.log(currentSlide);
	};
	// ========================================================
	return (
		<div style={{ marginBottom: '120px' }}>
			<div>
				<MainHeader />
			</div>
			<div className="mt-1">
				<img src={home} alt="" className="w-100" />
			</div>

			<div className="ml-2 mr-2" style={{ marginTop: '-15%' }}>
				<div className="user-home-container">
					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div className="ml-2 mt-2">
							<h2 style={{ fontSize: '25px' }}>{userData?.name}</h2>
							<img
								src={icon}
								alt=""
								style={{ width: 'auto', objectFit: 'contain' }}
							/>
							<span className="ml-1" style={{ fontSize: '16px' }}>
								****
								{userData?.phone?.slice(
									userData?.phone?.length - 4,
									userData?.phone?.length
								) || '***'}
							</span>
						</div>
						<div className="mr-2 mt-2">
							<h3
								style={{
									fontWeight: '400',
									fontSize: getLanguage === 'bm' ? '15px' : 'normal',
								}}
								className="text-center"
							>
								{t('Points')}
							</h3>
							<h1
								style={{
									fontSize: '33px',
									fontWeight: '800',
									textAlign: 'right',
								}}
							>
								{userData?.totalUnitsBalance || 0}
							</h1>
						</div>
					</div>

					<div style={{ display: 'flex', justifyContent: 'space-between' }}>
						<div
							className="ml-2 mt-2"
							style={{
								width: '30%',
								alignItems: 'center',
								display: 'flex',
								justifyContent: 'space-between',
								gap: '5px',
							}}
						>
							<div>
								<img
									src={
										userData?.tier?.name === 'Bronze'
											? bronzeMedal
											: userData?.tier?.name === 'Silver'
											? silverMedal
											: userData?.tier?.name === 'Gold'
											? goldMedal
											: userData?.tier?.name === 'Platinum'
											? platinumMedal
											: bronzeMedal
									}
									alt=""
									style={{ width: '16%' }}
								/>

								{isSGRegion ? (
									<span
										className=""
										style={{ fontWeight: '800', fontSize: '16px' }}
									>
										{' '}
										{userData?.tier?.name || 'Bronze'}
									</span>
								) : (
									<span
										className=""
										style={{ fontWeight: '800', fontSize: '14px' }}
									>
										{' '}
										{userData?.tier?.name === 'Platinum'
											? t('userPlatinum')
											: userData?.tier?.name === 'Gold'
											? t('userGold')
											: userData?.tier?.name === 'Silver'
											? t('userSilver')
											: t('userBronze') || t('userBronze')}
									</span>
								)}
							</div>
						</div>
						<div className="" style={{ width: '70%' }}>
							<div style={{ position: 'relative', right: '20px' }}>
								<Progress
									percent={
										userData?.tier?.name === 'Bronze'
											? (userData?.totalUnitsEarnedLastCycle / 250) * 100
											: userData?.tier?.name === 'Silver'
											? (userData?.totalUnitsEarnedLastCycle / 600) * 100
											: userData?.tier?.name === 'Gold'
											? (userData?.totalUnitsEarnedLastCycle / 1850) * 100
											: userData?.tier?.name === 'Platinum'
											? // ? ((userData?.totalUnitsEarnedLastCycle - 1850) / 1850) *
											  //   100
											  (userData?.totalUnitsEarnedLastCycle / 1) * 100
											: 0 / 250
									}
									strokeColor={'#30E700'}
									showInfo={false}
								/>
							</div>
							<div
								style={{
									display: 'flex',
									justifyContent: 'right',
									marginRight: '30px',
								}}
							>
								<p style={{ fontWeight: '800' }} className="fontSize-16">
									{userData?.tier?.name === 'Bronze'
										? `${userData?.totalUnitsEarnedLastCycle || 0}/250`
										: userData?.tier?.name === 'Silver'
										? `${userData?.totalUnitsEarnedLastCycle || 0}/600`
										: userData?.tier?.name === 'Gold'
										? `${userData?.totalUnitsEarnedLastCycle || 0}/1000`
										: userData?.tier?.name === 'Platinum'
										? userData?.totalUnitsEarnedLastCycle
										: `0/250`}
								</p>
							</div>
						</div>
					</div>
				</div>

				<div
					style={{
						display: 'flex',
						justifyContent: 'space-between',
						fontSize: '18px',
						marginTop: '5%',
					}}
				>
					<div
						className="box-container-left"
						onClick={() =>
							  navigate('/pointshop')
						}
					>
						{getLanguage === 'bm' ? (
							<p
								className="mt-1 ml-1 "
								style={{ fontSize: '18px', fontWeight: '600' }}
							>
								Lihat Semua
								<br /> Ganjaran
							</p>
						) : getLanguage === 'cn' ? (
							<p
								className="mt-1 ml-1 "
								style={{ fontSize: '18px', fontWeight: '600' }}
							>
								浏览所有奖励
							</p>
						) : (
							<p
								className="mt-1 ml-1 "
								style={{ fontSize: '18px', fontWeight: '600' }}
							>
								Browse All <br />
								Rewards
							</p>
						)}
						<img
							src={user1}
							alt=""
							style={{
								position: 'absolute',
								right: '8%',
								bottom: '13%',
								width: '15%',
								objectFit: 'contain',
							}}
						/>
					</div>
					<div className="box-container-right" onClick={() => setHowTo(true)}>
						{getLanguage === 'bm' ? (
							<p
								className="mt-1 ml-1"
								style={{ fontSize: '18px', fontWeight: '600' }}
							>
								Cara Mendapatkan
								<br /> Mata Ganjaran
							</p>
						) : getLanguage === 'cn' ? (
							<p
								className="mt-1 ml-1"
								style={{ fontSize: '18px', fontWeight: '600' }}
							>
								如何获取积分
							</p>
						) : (
							<p
								className="mt-1 ml-1"
								style={{ fontSize: '18px', fontWeight: '600' }}
							>
								How To Earn
								<br />
								Points?
							</p>
						)}
						<img
							src={user2}
							alt=""
							style={{
								position: 'absolute',
								right: '6%',
								bottom: '11%',
								width: '18%',
								objectFit: 'contain',
							}}
						/>
					</div>
				</div>
				<HowToEarnPoints howTo={howTo} setHowTo={setHowTo} />
				<a
					href={
						isSGRegion
							? 'https://shopee.sg/haleonsg'
							: 'https://s.shopee.com.my/1qFn3BGMk3?share_channel_code=1'
					}
					target="_blank"
					rel="noreferrer"
					style={{
						textDecoration: 'none',
						color: 'inherit',
					}}
				>
					<div
						className="store-container"
						style={{
							display: 'flex',
							marginTop: '5%',
							justifyContent: 'space-evenly',
							alignItems: 'center',
						}}
					>
						<span
							style={{
								fontSize: getLanguage === 'bm' ? '15px' : '20px',
								fontWeight: 'bold',
							}}
						>
							{ t('Visit Haleon Online Store')}
						</span>
						<img
							alt=""
							src={storeImg}
							style={{ width: '10%', height: 'auto', objectFit: 'contain' }}
							className=""
						/>
					</div>
				</a>

				<h2 className="t-black text-center" style={{ paddingTop: '10%' }}>
					{t("What's New")}
				</h2>

				{/* banner Image  */}
				{isSGRegion ? (
					<div style={{ paddingTop: '5%' }}>
						<Sg_carousel />
					</div>
				) : (
					<div className="carousel-container">
						<div
							onClick={() => navigate('/badges')}
							className="mt-2 "
							style={{ position: 'relative' }}
						>
							<img
								src={carousel2}
								alt=""
								className="w-100"
								style={{ objectFit: 'contain' }}
							/>

							{/* countdown timer */}
							<div
								style={{
									position: 'absolute',
									top: '5%',
									right: '4%',
									width: '52%',
								}}
							>
								<div className="countdown-container">
									<div>
										<div className="countdown-time">
											{formatTime(timeLeft.days)}
										</div>
										<div className="countdown-label">Day</div>
									</div>
									<div>
										<div className="countdown-time">
											{formatTime(timeLeft.hours)}
										</div>
										<div className="countdown-label">Hr</div>
									</div>
									<div>
										<div className="countdown-time">
											{formatTime(timeLeft.minutes)}
										</div>
										<div className="countdown-label">Min</div>
									</div>
								</div>
							</div>
						</div>
					</div>
				)}

				{/* Star Brands */}
				<div className="text-center mt-3">
					<div className="star-brands-position">
						{/* <img src={starBrands} alt="" style={{ width: '65%' }} /> */}
						<p className="font-weight-600" style={{ fontSize: '25px' }}>
							{t('Our Star Brands')}
						</p>
					</div>
					<div className="star-brands-container" style={{ paddingTop: '5%' }}>
						<div className="row">
							<a
								href={
									isSGRegion
										? 'https://www.parodontax.com/en-sg/'
										: 'https://shopee.com.my/scp/76617755/eyJjaWQiOjY3NTE3LCJzaWQiOjc2NjE2MjgzLCJpc0RyYWZ0IjpmYWxzZX0%3D'
								}
								target="_blank"
								rel="noreferrer"
								// style={{ pointerEvents: 'none' }}
							>
								<img
									src={isSGRegion ? storesg1 : store1}
									alt="sdsdzxz"
									style={{ width: '96%' }}
								/>
							</a>
							<a
								href={
									isSGRegion
										? 'https://www.watsons.com.sg/all-brands/b/227297/scott-s'
										: 'https://shopee.com.my/scp/76617755/eyJjaWQiOjY3NTEwLCJzaWQiOjc2NjE2MjgzLCJpc0RyYWZ0IjpmYWxzZX0%3D'
								}
								target="_blank"
								rel="noreferrer"
								// style={{ pointerEvents: 'none' }}
							>
								<img src={store2} alt="scotts" style={{ width: '96%' }} />
							</a>
							<a
								href={
									isSGRegion
										? 'https://www.sensodyne.com.sg/'
										: 'https://shopee.com.my/scp/76617755/eyJjaWQiOjY3NTA0LCJzaWQiOjc2NjE2MjgzLCJpc0RyYWZ0IjpmYWxzZX0%3D'
								}
								target="_blank"
								rel="noreferrer"
								// style={{ pointerEvents: 'none' }}
							>
								<img src={store3} alt="sensodyne" style={{ width: '96%' }} />
							</a>
						</div>
						<div className="row mt-2">
							<a
								href={
									isSGRegion
										? 'https://www.centrum.sg/'
										: 'https://shopee.com.my/scp/76617755/eyJjaWQiOjY3NTE2LCJzaWQiOjc2NjE2MjgzLCJpc0RyYWZ0IjpmYWxzZX0%3D'
								}
								target="_blank"
								rel="noreferrer"
								// style={{ pointerEvents: 'none' }}
							>
								<img src={store4} alt="centrum" style={{ width: '96%' }} />
							</a>
							<a
								href={
									isSGRegion
										? 'https://www.caltrate.sg/'
										: 'https://shopee.com.my/scp/76617755/eyJjaWQiOjY3NTE0LCJzaWQiOjc2NjE2MjgzLCJpc0RyYWZ0IjpmYWxzZX0%3D'
								}
								target="_blank"
								rel="noreferrer"
								// style={{ pointerEvents: 'none' }}
							>
								<img src={store5} alt="caltrate" style={{ width: '96%' }} />
							</a>
							<a
								href={
									isSGRegion
										? 'https://www.polident.com/en-sg/'
										: 'https://shopee.com.my/scp/76617755/eyJjaWQiOjY3NTE4LCJzaWQiOjc2NjE2MjgzLCJpc0RyYWZ0IjpmYWxzZX0%3D'
								}
								target="_blank"
								rel="noreferrer"
								// style={{ pointerEvents: 'none' }}
							>
								<img src={store6} alt="polident" style={{ width: '96%' }} />
							</a>
						</div>
					</div>
				</div>

				{/* Remove receipt submission status */}
				{/* <div className="submission-card mt-5">
					<div className="header-card">
						<p
							className="t-black fontSize-1"
							style={{ marginTop: '2%', marginLeft: '3%' }}
						>
							Every Purchase Gets
						</p>
					</div>

					<div className="mid-header-container t-white">
						<div style={{ display: 'flex', justifyContent: 'space-between' }}>
							<div
								style={{
									width: '50%',
									lineHeight: '40px',
									justifyContent: 'center',
								}}
								className="mt-2"
							>
								<h1 style={{ fontSize: '48px' }} className="text-center">
									RM 5
								</h1>
								<p style={{ fontSize: '11px' }} className="text-center">
									Min. Spend of RM 38
								</p>
							</div>

							<div style={{ justifyContent: 'center' }}>
								<img src={line} alt="" style={{ height: '100%' }} />
							</div>

							<div className="d-flex rewards-position">
								<img
									src={tng}
									alt="tng"
									style={{ width: '35%', objectFit: 'contain' }}
								/>
								<img
									src={grab}
									alt="grab"
									style={{ width: '40%', objectFit: 'contain' }}
								/>
							</div>
						</div>
					</div>

					<div className="t-black mt-2">
						<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
							<div>
								<img
									src={icon1}
									alt="upload"
									className="ml-1"
									style={{ width: '10%' }}
								/>
								<span
									className="fontSize-18"
									style={{
										fontWeight: '800',
										position: 'relative',
										bottom: '5px',
									}}
								>
									{' '}
									My Submissions
								</span>
							</div>
							<div>
								<p
									className="fontSize-12"
									style={{ textAlign: 'left', fontWeight: '300' }}
								>
									Month:<b> {getCurrMonth}</b>
								</p>
							</div>
						</div>
					</div>
					<div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
						{uploadHistoryItemsToRender.map((_, index) => {
							const entry = uploadHistory[index];
							const receipt = entry?.receipts?.[0];

							const statusImgSrc = {
								APPROVED: approvedPic,
								COMPLETED: approvedPic,
								PENDING: pendingPic,
								CANCELLED: rejectedPic,
								REJECTED: rejectedPic,
							};

							const statusText = {
								APPROVED: 'Approved',
								COMPLETED: 'Approved',
								PENDING: 'Pending',
								CANCELLED: 'Rejected',
								REJECTED: 'Rejected',
							};

							const entryStatusTitle = {
								APPROVED: 'Approved',
								COMPLETED: 'Approved',
								PENDING: 'Pending Validation',
								CANCELLED: 'Rejected',
								REJECTED: 'Rejected',
							};

							const entryStatusDescription = {
								APPROVED: `Congratulations! Your receipt has been successfully approved, and you have been credited with ${
									receiptData?.amount || 'N/A'
								} points for this purchase.`,
								COMPLETED: `Congratulations! Your receipt has been successfully approved, and you have been credited with ${
									receiptData?.amount || 'N/A'
								} points for this purchase.`,
								PENDING:
									'Your submission is currently under review by our team. Please check back later for updates.',
								CANCELLED:
									'Oops! Your submission has been rejected due to a blurry image of the receipt. Please resubmit a clear photo of the receipt for verification.',
								REJECTED:
									'Oops! Your submission has been rejected due to a blurry image of the receipt. Please resubmit a clear photo of the receipt for verification.',
							};

							return (
								<div className="text-center mt-1" key={index}>
									<img
										alt=""
										src={
											receipt && statusImgSrc[receipt?.status]
												? statusImgSrc[receipt?.status]
												: uploadNew
										}
										style={{ width: '50px' }}
										onClick={() => {
											checkReceiptStatus(receipt ? receipt : 'new');
										}}
									/>
									<p className="font-weight-700 text-center">
										{receipt ? statusText[receipt?.status] : 'Upload New'}
									</p>

									<div>
										<Modal
											open={receiptData && receiptModal}
											centered
											header={null}
											footer={null}
											closable={false}
											width={320}
										>
											<div className="text-center">
												<img
													src={receiptData?.imageUrl}
													alt="uploadpic"
													style={{ width: '50%' }}
												/>
												<h2>
													{receiptData
														? entryStatusTitle[receiptData?.status]
														: ''}
												</h2>
												<hr
													style={{
														backgroundColor: '#30EA03',
														height: '3px',
														border: 'none',
														margin: 'auto',
														width: '35%',
													}}
												/>
												<div className="submit-pending-modal-font mt-1">
													<p className="fontSize-12 text-center">
														{receiptData
															? entryStatusDescription[receiptData?.status]
															: ''}
													</p>
												</div>

												{receiptData?.status === 'REJECTED' && (
													<Button
														onClick={() =>
															receiptData?.status === 'REJECTED'
																? navigate('/upload', {
																		state: {
																			receiptData: {
																				id: receiptData.campaignEntryId,
																			},
																		},
																  })
																: navigate('/upload')
														}
														className="mt-2"
														style={{
															backgroundColor: 'black',
															color: 'white',
															width: '90%',
															border: 'none',
															borderRadius: ' 5px',
															height: '35px',
															fontWeight: '600',
														}}
													>
														Resubmit
													</Button>
												)}
												<Button
													className="coming-soon-button mt-1"
													onClick={() => {
														setReceiptData();
														setReceiptModal(false);
													}}
												>
													Close
												</Button>
											</div>
										</Modal>
									</div>
								</div>
							);
						})}
					</div>
				</div> */}

				{!isSGRegion && (
					<div>
						<div
							style={{ display: 'flex', justifyContent: 'space-between' }}
							className="mt-3"
						>
							<h2>{t('Popular Rewards')}</h2>
							<h2
								style={{ color: 'grey', fontSize: '18px', marginTop: '3px' }}
								onClick={() => navigate('/pointshop')}
							>
								{t('View All')}
							</h2>
						</div>
						{<ScrollableImages isSoon={isSoon} setIsSoon={setIsSoon} />}
					</div>
				)}
			</div>
			<div style={{ justifyContent: 'center', display: 'flex' }}>
				<UserFooter type={'homePage'} />
			</div>

			<div>
				<Modal
					open={isWPModalOpen}
					centered
					header={null}
					footer={null}
					closable={true}
					onCancel={() => setIsWPModalOpen(false)}
					width={320}
				>
					<div className="text-center">
						<img style={{ width: '60%' }} src={welcome} alt=""></img>
						<h2>{t('Welcome Reward')}!</h2>
						<div className="submit-pending-modal-font mt-1">
							{getLanguage === 'bm' ? (
								<p className="fontSize-12 text-center">
									Selamat datang ke <b>Haleon Rewards</b>! <br />
									Sebagai tanda penghargaan, kami
									<br /> memberikan anda <br />
									<b style={{ fontSize: '20px', fontWeight: '800' }}>
										10 Mata Ganjaran!
									</b>
								</p>
							) : getLanguage === 'cn' ? (
								<p className="fontSize-12 text-center">
									欢迎加入 <b>Haleon Rewards</b>! <br />
									点击下方按钮即可领取 <br />
									<b style={{ fontSize: '20px', fontWeight: '800' }}>
										10 积分!
									</b>
								</p>
							) : (
								<p className="fontSize-12 text-center">
									Welcome to <b>Haleon Rewards</b>! <br />
									As a token of appreciation, we present you with <br />
									<b style={{ fontSize: '20px', fontWeight: '800' }}>
										{'10 Points!'}
									</b>
								</p>
							)}
							<p
								className="fontSize-12 text-center"
								hidden={wpClaimedMsg?.length <= 0}
								style={{
									color: 'black',
								}}
							>
								{wpClaimedMsg}
							</p>
						</div>
						<Button
							className="coming-soon-button mt-2"
							style={{ fontWeight: '600' }}
							onClick={getRegisterReward}
							disabled={['Claimed!', 'Claiming...'].includes(wpClaimBtnLabel)}
						>
							{wpClaimBtnLabel}
						</Button>
					</div>
				</Modal>
				<Modal
					open={isLoading}
					centered
					header={null}
					footer={null}
					closable={false}
					maskClosable={false}
					width={320}
				>
					<div
						style={{
							padding: '30px 50px',
							textAlign: 'center',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							gap: '5%',
						}}
					>
						<span className="loading-text">Checking for eligible rewards </span>
						<Spin />
					</div>
				</Modal>
				<Modal
					open={!isLoading && rewardDetected}
					centered
					header={null}
					footer={null}
					closable={false}
					width={320}
				>
					<div className="text-center">
						<img
							style={{ width: '62px', objectFit: 'contain' }}
							src={badgeImg}
							alt=""
						/>
						<h2
							style={{ fontSize: '24px', fontWeight: 'bold' }}
							className="mt-1"
						>
							Your Badge Rewards Are Ready!
						</h2>
						<div className="submit-pending-modal-font mt-1">
							<p
								className="text-center"
								style={{
									marginLeft: '10%',
									marginRight: '10%',
									fontSize: '13px',
								}}
							>
								You have unclaimed badge rewards waiting for you. Claim them
								now!
							</p>
						</div>
						<Button
							className="coming-soon-button mt-2"
							style={{ fontWeight: '600' }}
							onClick={() => navigate('/badges')}
						>
							Claim Now!
						</Button>
						<Button
							className="mt-1"
							style={{
								width: '90%',
								background: 'transparent',
								color: 'black',
								borderRadius: '5px',
								height: '35px',
								border: '1px solid black',
								fontWeight: 'bold',
							}}
							onClick={() => setRewardDetected(false)}
						>
							Later
						</Button>
					</div>
				</Modal>

				{!isSGRegion &&
				!isWPModalOpen &&
				displayQues &&
				!questionSubmitted &&
				(createdAtMoment >= '2024-02-29' ||
					createdAtMoment === '2024-02-29') ? (
					<Questionnaire
						displayQues={displayQues}
						setDisplayQues={setDisplayQues}
						channel={QUESTIONNAIRE_ID}
					/>
				) : isSGRegion &&
				  !isWPModalOpen &&
				  displayQues &&
				  !questionSubmitted &&
				  (createdAtMoment >= '2024-02-29' ||
						createdAtMoment === '2024-02-29') ? (
					<JubileeQuestionnaire
						displayQues={displayQues}
						setDisplayQues={setDisplayQues}
						jubileeCampaignId={JUBILEE_QUESTIONNAIRE_ID}
					/>
				) : null}

				{/* <ComingSoon isSoon={isSoon} setIsSoon={setIsSoon} /> */}
			</div>
		</div>
	);
};

export default Home;
